import React, { useState, useEffect } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { SiteCard } from "./SiteCard";

import { useLocation } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import axios from "axios";
import { Button } from "@mui/material";

const Pagination = ({ page, setPage, totalPages }) => {
  return (
    <div
      style={{
        marginTop: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Button onClick={() => setPage(page - 1)} disabled={page === 1}>
        Previous
      </Button>
      <Typography>
        Page {page} of {totalPages}
      </Typography>
      <Button onClick={() => setPage(page + 1)} disabled={page === totalPages}>
        Next
      </Button>
    </div>
  );
};

export const SearchSiteResult = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get("query");

  const [searchResults, setSearchResults] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    async function fetchQueryResult() {
      try {
        const response = await axios.get(
          `/sites/search?query=${searchQuery}&page=${currentPage}`
        );

        if (response.status !== 200) throw new Error(response.error);

        const { data } = response;

        setSearchResults(data);
        console.log(data);
      } catch (e) {
        console.error(e);
      }
    }
    if (searchQuery && searchQuery.length > 0) fetchQueryResult();
  }, [currentPage]);

  return (
    <>
      <Header />

      {searchResults &&
        Object.keys(searchResults).length > 0 &&
        searchResults.results &&
        searchResults.results.length > 0 && (
          <Pagination
            page={currentPage}
            setPage={setCurrentPage}
            totalPages={searchResults.total_pages}
          />
        )}

      {searchResults &&
        Object.keys(searchResults).length > 0 &&
        searchResults.results &&
        searchResults.results.length > 0 && (
          <Grid
            container
            spacing={2}
            sx={{
              pt: 3,
              mt: 3,
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
          >
            {searchResults.results.map((data, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <SiteCard
                  key={index}
                  logo={data.logo}
                  name={data.name}
                  siteId={data._id}
                  category="App"
                />
              </Grid>
            ))}
          </Grid>
        )}

      {searchResults &&
        Object.keys(searchResults).length > 0 &&
        searchResults.results &&
        searchResults.results.length > 0 && (
          <Pagination
            page={currentPage}
            setPage={setCurrentPage}
            totalPages={searchResults.total_pages}
          />
        )}

      {!searchResults ||
        (Object.keys(searchResults).length === 0 && (
          <Typography sx={{ textAlign: "center" }}>No Results Found</Typography>
        ))}
      <Footer />
    </>
  );
};
