import React, { useState, useEffect, useContext, useRef } from "react";

import { Header } from "./Header";
import { Footer } from "./Footer";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { allAppsContext } from "../App";

import { SiteCard } from "./SiteCard";
import { SearchSiteResultList } from "./SearchSiteResultList";

import {
  Grid,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Tabs,
  Tab,
  Rating,
} from "@mui/material";
import { Search, Star } from "@mui/icons-material";

let appCategories = [
  "All",
  "Art & Design",
  "Augmented Reality",
  "Auto & Vehicles",
  "Beauty",
  "Books & Reference",
  "Books",
  "Business",
  "Catalogs",
  "Comics",
  "Communication",
  "Dating",
  "Daydream",
  "Education",
  "Entertainment",
  "Events",
  "Finance",
  "Food & Drink",
  "Games",
  "Health & Fitness",
  "House & Home",
  "Libraries & Demo",
  "Lifestyle",
  "Maps & Navigation",
  "Medical",
  "Music & Audio",
  "Music",
  "Navigation",
  "News & Magazines",
  "News",
  "Parenting",
  "Personalization",
  "Photo & Video",
  "Photography",
  "Productivity",
  "Reference",
  "Shopping",
  "Social",
  "Social Networking",
  "Sports",
  "Tools",
  "Travel & Local",
  "Travel",
  "Utilities",
  "Video Players & Editors",
  "Wear OS by Google",
  "Weather",
];

export const HomePage = () => {
  const [selectedAppCategoryTab, setSelectedAppCategoryTab] = useState(0); //State used to get the selected app category Index among the list of categories

  const [sites, setSites] = useState([]); // Get all the sites data present

  const { sitesLikedByUser, setSitesLikedByUser } = useContext(allAppsContext); //Get all the apps Liked by the user
  const [searchSiteData, setSearchSiteData] = useState(""); //State used when user types in the search box
  const [searchSiteResultData, setSearchSiteResultData] = useState({}); // This state is used to store the search data when the user enters the query

  const searchControllerRef = useRef(); //This reference is used to make sure to abort or cancel the request if there was an outgoing request sent to the server
  const navigate = useNavigate();

  //Get all the sites liked by the user
  useEffect(() => {
    async function fetchSites() {
      try {
        const response = await axios.get(`/sites`);

        if (response.status !== 200) {
          throw new Error("Failed to fetch the Sites data");
        }
        const { data } = response;
        setSites(data);
      } catch (error) {
        console.error("Error fetching Sites data");
      }
    }

    fetchSites();
  }, []);

  //UseEffect executed when user tries to search a site
  const handleAppCategoryChange = (event, newValue) => {
    setSelectedAppCategoryTab(newValue);
  };

  //Set the search Value in the state and perform api call to get the search Results
  const handleSearchChange = (e) => {
    const newSearchValue = e.target.value;

    setSearchSiteResultData({}); // This code is place here to remove the exisiting search Result data
    setSearchSiteData(e.target.value);

    //If there was a request which was already sent to the server for searching the data. Then we should abort it and send a new Request
    if (searchControllerRef.current) {
      searchControllerRef.current.abort();
    }

    if (newSearchValue && newSearchValue.length > 0)
      getSearchResult(newSearchValue);
  };

  //This function is executed when the user hits the enter button inside the search box or when the user hits search button. Data should be present inside the state
  const handleSearchSubmit = () => {
    if (
      searchSiteData &&
      searchSiteData.length > 0 &&
      Object.keys(searchSiteResultData).length > 0 &&
      searchSiteResultData.results &&
      searchSiteResultData.results.length > 0
    ) {
      navigate(`/sites/search?query=${searchSiteData}`);
    }
  };

  const getSearchResult = async (searchQuery) => {
    if (!searchQuery || searchQuery.length === 0) return;

    searchControllerRef.current = new AbortController();
    const signal = searchControllerRef.current.signal;

    try {
      const response = await axios.get(`/sites/search?query=${searchQuery}`, {
        signal: signal,
      });

      if (response.status !== 200) {
        throw new Error(response.error);
      }

      const { data } = response;
      setSearchSiteResultData(data);

      console.log(data);

      console.log("hello world");
    } catch (error) {
      console.error(error);
    }
  };

  //CSS Styling code when a particular app category is selected
  const getTabStyle = (index) => {
    if (index === selectedAppCategoryTab) {
      return {
        backgroundColor: "#e67e22",
        borderRadius: 1,
        color: "white",
        "&.Mui-selected": {
          color: "white",
        },
      };
    }
    return {
      color: "inherit",
      "&.Mui-selected": {
        color: "inherit",
      },
    };
  };

  return (
    <>
      <Header />

      <Grid
        container
        sx={{
          width: "100%",
          height: { xs: "max-content", sm: "430px" },
          backgroundColor: "#FFF0E3",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={7}
          sx={{
            mx: { xs: "auto", sm: 0 },
            my: "auto",
            pt: { xs: 1, sm: 0 },
            pl: 6,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box>
              <Typography
                sx={{
                  color: "#A1550F",
                  fontWeight: 700,
                  fontSize: "h5.fontSize",
                }}
              >
                Trusted reviews.
              </Typography>
              <Typography
                sx={{
                  color: "#A1550F",
                  fontWeight: 700,
                  fontSize: "h5.fontSize",
                }}
              >
                Seamless listings.
              </Typography>
            </Box>
            <Box sx={{ width: { xs: "90%", sm: "75%" } }}>
              <TextField
                variant="outlined"
                placeholder="Search for Company, Category, or App"
                value={searchSiteData}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#A1550F", // Custom input background color to  orange
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#A1550F", // Custom input background color to  orange
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#6E7491", // Custom placeholder color to grey
                      fontSize: { xs: "9px", sm: "12px", md: "1em" },
                    },
                    color: "black",
                    backgroundColor: "white", // Background color
                  },
                }}
                onChange={handleSearchChange}
                onKeyDown={(e) => {
                  if (e.key == "Enter") handleSearchSubmit();
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <Button
                      sx={{
                        border: 1,
                        borderColor: "#A1550F",
                        backgroundColor: "#A1550F",
                        width: "90px",
                        color: "#FFFFFF",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#A1550F",
                          color: "white",
                        },
                      }}
                      onClick={() => {
                        handleSearchSubmit();
                      }}
                    >
                      Search
                    </Button>
                  ),
                }}
              />
              {searchSiteResultData &&
                Object.keys(searchSiteResultData).length > 0 &&
                searchSiteResultData.results &&
                searchSiteResultData.results.length > 0 && (
                  <SearchSiteResultList
                    searchData={searchSiteResultData.results}
                    totalPages={searchSiteResultData.total_pages}
                  />
                )}
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          sx={{ mt: { xs: 2, sm: 0 }, my: { sm: "auto" }, pl: 2 }}
        >
          <Box
            sx={{
              borderRadius: "50%",
              width: { xs: "270px", md: "400px" },
              height: { xs: "270px", md: "400px" },
              position: "relative",
              mx: { xs: "auto", sm: 0 },
            }}
          >
            {/* Outer dark circle code */}
            <Box
              sx={{
                borderRadius: "50%",
                width: { xs: "240px", md: "350px" },
                height: { xs: "240px", md: "350px" },
                position: "absolute",
                bottom: "0px",
                left: "25px",
                backgroundColor: "#FFE1C7",
              }}
            />

            {/* Outer dark circle code  end*/}

            {/* Hero Image code */}
            <Box
              component={"img"}
              src="/utils/img/homeImage.png"
              sx={{
                position: "absolute",
                zIndex: 1000,
                bottom: "0px",
                left: "40px",
                width: { xs: "200px", md: "300px" },
                height: { xs: "300px", md: "450px" },
              }}
            />

            {/* Multiple app icons code */}
            <Box
              sx={{
                width: "75px",
                height: "75px",
                zIndex: 2000,
                backgroundColor: "white",
                position: "absolute",
                bottom: "10px",
                right: { xs: "10px", md: "30px" },
                borderRadius: 2,
              }}
            >
              <Box
                component={"img"}
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Spotify_icon.svg/1982px-Spotify_icon.svg.png"
                }
                alt={"spotify image"}
                width={"20px"}
                height={"20px"}
                sx={{ position: "absolute", left: "9px", top: "3px" }}
              />

              <Box
                component={"img"}
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Spotify_icon.svg/1982px-Spotify_icon.svg.png"
                }
                alt={"spotify image"}
                width={"20px"}
                height={"20px"}
                sx={{ position: "absolute", left: "5px", top: "25px" }}
              />

              <Box
                component={"img"}
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Spotify_icon.svg/1982px-Spotify_icon.svg.png"
                }
                alt={"spotify image"}
                width={"20px"}
                height={"20px"}
                sx={{ position: "absolute", left: "9px", top: "46px" }}
              />

              <Box
                component={"img"}
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Spotify_icon.svg/1982px-Spotify_icon.svg.png"
                }
                alt={"spotify image"}
                width={"20px"}
                height={"20px"}
                sx={{ position: "absolute", left: "32px", top: "50px" }}
              />

              <Box
                component={"img"}
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Spotify_icon.svg/1982px-Spotify_icon.svg.png"
                }
                alt={"spotify image"}
                width={"20px"}
                height={"20px"}
                sx={{ position: "absolute", left: "55px", top: "51px" }}
              />

              <Box
                component={"img"}
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Spotify_icon.svg/1982px-Spotify_icon.svg.png"
                }
                alt={"spotify image"}
                width={"40px"}
                height={"40px"}
                sx={{ position: "absolute", right: "5px", top: "5px" }}
              />
            </Box>
            {/* Multiple app icons code end */}

            {/* User Review Metric code start */}

            <Box
              sx={{
                backgroundColor: "white",
                display: "flex",
                width: { xs: "90px", sm: "130px" },
                height: { xs: "50px", sm: "50px" },
                gap: 2,
                borderRadius: "32px",
                zIndex: 3000,
                alignItems: "center",
                position: "absolute",
                bottom: { xs: "50px", sm: "20px", md: "120px" },
                left: { sm: "-30px" },
              }}
            >
              <Star />
              <Typography
                variant="p"
                sx={{
                  textTransform: "none",
                  color: "#27273F",
                  fontSize: { xs: "7px", sm: "10px" },
                  fontWeight: "bold",
                }}
              >
                User reviews for 4000+ apps
              </Typography>
            </Box>

            {/* User Review Metric code end */}

            {/* Review of a particular code start */}

            <Box
              sx={{
                width: { xs: "70px", sm: "95px" },
                height: { xs: "70px", sm: "95px" },
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                borderRadius: 2,
                position: "absolute",
                top: { xs: "80px", md: "110px" },
                p: 1,
                gap: 1,
                left: { xs: "-10px", sm: "-30px", md: "-10px" },
              }}
            >
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Box
                  component={"img"}
                  width={"15px"}
                  height={"15px"}
                  src={
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Spotify_icon.svg/1982px-Spotify_icon.svg.png"
                  }
                />
                <Rating
                  name="read-only"
                  value={5}
                  readOnly
                  sx={{ fontSize: { xs: "10px", sm: "14px" } }}
                />
              </Box>
              <Typography
                sx={{ color: "#3D3D3D", fontSize: { xs: "6px", sm: "8px" } }}
              >
                Not everyone wants to be a business and pay for followers and
                promotions. IG was already making enough with ad revenue.
              </Typography>
            </Box>

            {/* Review of a particular code end */}
          </Box>
        </Grid>
      </Grid>

      {/* Show the list of app categories */}
      <Tabs
        value={selectedAppCategoryTab}
        onChange={handleAppCategoryChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="category tabs"
        TabIndicatorProps={{ style: { display: "none" } }}
        indicatorColor="white"
        color="white"
        sx={{
          pb: 1,
          mt: 3,
          mx: 3,
        }}
      >
        {appCategories.map((category, index) => (
          <Tab key={category} label={category} sx={getTabStyle(index)} />
        ))}
      </Tabs>
      {/* Show the list of app categories code end*/}

      <Box sx={{ mx: 3, mt: 3 }}>
        <Typography sx={{ color: "#6E7491", ml: { xs: 1, sm: 5 } }}>
          Featured / All
        </Typography>
        <Typography
          fontWeight={"bold"}
          variant="p"
          sx={{ color: "#27273F", ml: { xs: 1, sm: 5 } }}
        >
          Trending Web Apps & PWAs
        </Typography>

        {/* Show the list of all apps  */}
        {sites && sites.length > 0 && (
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            aria-label="category tabs"
            TabIndicatorProps={{ style: { display: "none" } }}
            indicatorColor="white"
            color="white"
            value={false}
          >
            {sites.map((site, index) => (
              <Tab
                key={index}
                label={
                  <SiteCard
                    key={index}
                    logo={site.logo}
                    name={site.name}
                    siteId={site._id}
                    category="App"
                  />
                }
              />
            ))}
          </Tabs>
        )}
      </Box>

      {/* Show the list of all apps code end */}
      <Footer />
    </>
  );
};
