import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FormLogin } from "./components/forms/FormLogin";
import { FormRegister } from "./components/forms/FormRegister";
import { FormRecovery } from "./components/forms/FormRecovery";

import { Favorites } from "./components/Favorites";
import { FormNewApp } from "./components/forms/FormNewApp";
import { Layout } from "./components/Layout";
import { PrivateRoute } from "./components/PrivateRoute";
import { ExpandedInfo } from "./components/ExpandedInfo";
import { SearchResult } from "./components/SearchResult";
import { NotFound } from "./components/NotFound";
import { Profile } from "./components/Profile";
import { Dashboard } from "./components/Dashboard";
import { FormRegisterRefer } from "./components/forms/FormRegisterRefer";
import { AboutUs } from "./components/AboutUs";

import { BlogPost } from './components/BlogPost';
import { Product } from "./components/Product";
import { ContactUs } from "./components/ContactUs";

import { HomePage } from "./components/HomePage";
import { SearchSiteResult } from "./components/SearchSiteResult";


export const allAppsContext = React.createContext(null);

function App() {

  const [data, setData] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [topApps, setTopApps] = useState([]);
  const isMobile = window.innerWidth > 768;
  const [open, setOpen] = useState(isMobile);


  return (

    <allAppsContext.Provider
      value={{
        data,
        setData,
        dataUser,
        setDataUser,
        topApps,
        setTopApps,
        open,
        setOpen,
      }}
    >
      <div className="App" >
        <BrowserRouter>
          <Routes>

            <Route exact path="/" element={<HomePage />} />

            {/* <Route
              exact
              path="/"
              element={
                <Layout>
                  <Applications />
                </Layout>
              }
            /> */}


            <Route
              path="/app/:name/:id"
              element={
                <Layout>
                  <ExpandedInfo />
                </Layout>
              }
            />

            <Route
              exact
              path="/searching/:name"
              element={
                <Layout>
                  <SearchResult />
                </Layout>
              }
            />

            <Route
              exact
              path="/Favorites"
              element={
                <Layout>
                  <PrivateRoute>
                    <Favorites />
                  </PrivateRoute>
                </Layout>
              }
            />

            <Route
              exact
              path="/NewApp"
              element={
                <Layout>
                  <PrivateRoute>
                    <FormNewApp />
                  </PrivateRoute>
                </Layout>
              }
            />

            <Route exact path="/Login" element={<FormLogin />} />
            <Route exact path="/Signup" element={<FormRegister />} />
            <Route
              exact
              path="/Signup/Referral/:id"
              element={<FormRegisterRefer />}
            />
            <Route exact path="/RecoveryPassword" element={<FormRecovery />} />
            <Route exact path="/AboutUs" element={<AboutUs />} />
            <Route exact path="/blogs/:blogId" element={<BlogPost />} />
            <Route exact path="/sites/:siteId" element={<Product />} />

            <Route
              exact
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />

            <Route exact path="/Dashboard_admin" element={<Dashboard />} />

            <Route exact path="/ContactUs" element={<ContactUs />} />

            <Route exact path="/sites/search" element={<SearchSiteResult />} />
            <Route path='*' element={<NotFound />} />


          </Routes>
        </BrowserRouter>
      </div>
    </allAppsContext.Provider>

  );
}

export default App;
