import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Typography, Card, CardContent, CardMedia } from "@mui/material";

import { Star, Favorite } from "@mui/icons-material";

export const SiteCard = memo(({ logo, name, category, siteId }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        minWidth: { sm: 150, md: 200 },
        maxWidth: 345,
        borderRadius: 2,
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 2,
          pb: 2,
          backgroundColor: "#f5f5f5",
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: 120,
            height: 120,
            borderRadius: "50%",
            backgroundColor: "#1DB954",
          }}
          image={logo}
          alt={name}
          onClick={() => {
            navigate(`/sites/${siteId}`);
          }}
        />
      </Box>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography
              variant="p"
              component="div"
              align="left"
              sx={{ textTransform: "none" }}
              fontWeight={"bold"}
            >
              {name}
            </Typography>
            <Typography
              variant="p"
              component="div"
              color="text.secondary"
              align="left"
              sx={{ textTransform: "none" }}
            >
              {category}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography variant="body2" color="text.secondary">
                5.0
              </Typography>
              <Star fontSize="small" sx={{ color: "#FFAE1B" }} />
            </Box>
          </Box>

          <Favorite sx={{ color: "red" }} />
        </Box>
      </CardContent>
    </Card>
  );
});
