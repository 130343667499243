import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  Grid,
  Avatar,
  CardContent,
  CardHeader,
  Typography,
  Box,
} from "@mui/material";

import NorthEastIcon from "@mui/icons-material/NorthEast";
import { Link } from "react-router-dom";

const styles = {
  overallDiv: {
    textAlign: "left", // Note: This is because App.css has text-align: "center"
  },
  contentBox: {
    paddingRight: "23vw",
    paddingLeft: "23vw",
    marginTop: "9vh",
    marginBottom: "12vh",
  },
  markdownContentBox: {
    color: "#475467",
    fontSize: "18px",
    "& p": {
      display: "flex",
      justifyContent: "center",
    },
    "& img": {
      maxWidth: "100%",
      maxHeight: "65vh",
    },
    "h1,h2,h3,h4,h5,h6": {
      color: "#101828",
    },
    marginBottom: "7vh",
  },
  cardHeader: {
    padding: 0,
    title: {
      fontWeight: 600,
      fontSize: "18px",
    },
    subHeading: {
      fontSize: "16px",
    },
  },
  avatar: {
    width: "56px",
    height: "56px",
  },
  copyLinkBtn: {
    color: "#344054",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    padding: "10px 16px 10px 16px",
    fontSize: "14px",
    fontWeight: 600,
    // width: "123px",
    height: "40px",
    boxShadow: "0px 1px 2px 0px #1018280D",
    icon: {
      color: "#344054",
    },
  },
  socialMediaIcon: {
    color: "#344054",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    fontWeight: 600,
    padding: "2px",
    minWidth: "40px",
    height: "40px",
    boxShadow: "0px 1px 2px 0px #1018280D",
    icon: {
      color: "#98A2B3",
      width: "22px",
      height: "22px",
    },
    ".MuiButton-startIcon": {
      marginRight: 0,
    },
  },
  contentHeader: {
    marginTop: "10vh",
    // height: "70vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  publishedDate: {
    color: "#D27B2C",
    fontWeight: 600,
    fontSize: "16px",
  },
  blogTitle: {
    color: "#101828",
    fontSize: "48px",
    fontWeight: 600,
  },
  blogSubTitle: {
    color: "#475467",
    fontSize: "20px",
    fontWeight: 400,
  },
  blogPostCategories: {
    display: "flex",
    justifyContent: "center",
    marginRight: "3vw",
    marginTop: "4vh",
    marginBottom: "4vh",
    ".MuiBadge-badge": {
      position: "relative",
      fontSize: "14px",
      padding: "2px 10px 2px 10px",
      borderRadius: "16px",
    },
    ".MuiBadge-root:nth-child(1) .MuiBadge-badge": {
      background: "#FFF0E3",
      color: "#A1550FCC",
    },
    ".MuiBadge-root:nth-child(2) .MuiBadge-badge": {
      background: "#EEF4FF",
      color: "#3538CD",
    },
    ".MuiBadge-root:nth-child(3) .MuiBadge-badge": {
      background: "#FDF2FA",
      color: "#C11574",
    },
  },
  blogTitleImage: {
    width: "65%",
    height: "100%",
    margin: "auto",
  },
  recommendationsDivider: {
    marginBottom: "4vh",
    marginLeft: "7vw",
    marginRight: "7vw",
  },
  recommendationsBox: {
    // border: "1px solid black",
    // height: "60vh",
    marginLeft: "7vw",
    marginRight: "7vw",
    marginBottom: "10vh",
    marginTop: "10vh",
  },
  recommondationsHeading: {
    fontSize: "36px",
    fontWeight: 600,
    color: "#101828",
  },
  recommondatonsSubheading: {
    fontSize: "20px",
    fontWeight: 400,
    color: "#475467",
    marginTop: "1vh",
  },
  recommendedBlog: {
    // width: "27vw",
    // height: "50vh",
    padding: "2px 24px 32px 24px",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: 1,
  },
  recommendedBlogAvatar: {
    width: "40px",
    height: "40px",
  },
  blogCards: { marginTop: "10vh" },
  blogCardContent: {
    padding: 0,
    display: "flex",
    flexDirection: "column",

    // justifyContent: "space-evenly", // Note: This can be changed to space-between as well depending on how we want it
    minHeight: "25rem",
    marginBottom: "2vh",
  },
  blogCardImg: {
    width: "100%",
    height: "30vh",
    maxHeight: "40vh",
    objectFit: "cover",
  },
  blogCardAvatar: { padding: 0 },
  blogCardAvatarTitle: {
    fontWeight: 600,
    color: "#101828",
    textAlign: "left",
  },
  blogCardTypo1: {
    fontSize: "14px",
    color: "#A1550FCC",
    fontWeight: 600,
    marginTop: "0.8rem",
  },

  blogCardTypo2: {
    fontSize: "24px",
    color: "#101828",
    fontWeight: 600,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",

    marginTop: "0.8rem",
  },
  blogCardDesc: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "16px",
    color: "#475467",
    fontWeight: 400,
    marginTop: "0.8rem",
  },
};

//This function is used to convert the date from example 2022-01-20T00:00:00.000+00:00  to 2022-01-20
const convertDate = (timestamp) => {
  const date = new Date(timestamp);
  const extractedDate = date.toISOString().split("T")[0];
  return extractedDate;
};

function BlogCard({ data }) {
  return (
    <Grid item sx={styles.recommendedBlog}>
      <CardContent sx={styles.blogCardContent}>
        <img
          src={data.image}
          alt="Blog Card image"
          style={styles.blogCardImg}
        />
        {data.category.length > 1 ? (
          <Typography sx={styles.blogCardTypo1}>
            {data.category[0].name} +{data.category.length - 1} more
          </Typography>
        ) : (
          <Typography sx={styles.blogCardTypo1}>
            {data.category[0].name}
          </Typography>
        )}

        <Typography sx={styles.blogCardTypo2} align="left">
          {data.name}
          <NorthEastIcon />
        </Typography>
        <Box sx={styles.blogCardDesc}>
          <Typography align="left">{data.description}</Typography>
        </Box>
      </CardContent>
      <CardHeader
        title={data.author.name}
        subheader={convertDate(data.date_modified)}
        avatar={
          <Avatar
            alt={data.author.name}
            src={data.author.avatar}
            sx={styles.recommendedBlogAvatar}
          ></Avatar>
        }
        sx={styles.blogCardAvatar}
        titleTypographyProps={styles.blogCardAvatarTitle}
        subheaderTypographyProps={{
          textAlign: "left",
        }}
      ></CardHeader>
    </Grid>
  );
}

export const BlogPostRecommendations = () => {
  //Getting the top Blog Post cards
  const [blogRecommendations, setBlogRecommendations] = useState([]);

  useEffect(() => {
    async function fetchBlogCards() {
      try {
        const response = await axios.get(`/blogs`);

        if (response.status !== 200) {
          throw new Error("Failed to fetch the blog posts");
        }

        const { data } = response;

        setBlogRecommendations(data);
      } catch (error) {
        console.error("Error fetching blog data", error);
      }
    }
    fetchBlogCards();
  }, []);

  return (
    <Grid
      container
      spacing={3}
      sx={{
        p: 3,
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      {blogRecommendations.length > 0 &&
        blogRecommendations.map((blogCard) => {
          return (
            <Grid item sm={6} md={6} xs={12}>
              <Link
                to={`/blogs/${blogCard._id}`}
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <BlogCard
                  key={blogCard._id}
                  data={blogCard}
                  sx={{
                    "&:hover": { cursor: "pointer" },
                  }}
                ></BlogCard>
              </Link>
            </Grid>
          );
        })}
    </Grid>
  );
};
