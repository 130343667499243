import React from "react";
import { Header } from "./Header";
import {
  Container,
  Typography,
  Box,
  useMediaQuery,
  Grid,
  Card,
  CardMedia,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Footer } from "./Footer";
import MediaImg from "./globalComponents/imagens/media.png";
import { BlogPostRecommendations } from "./BlogPostRecommendations";

export const AboutUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Header />

      <Container
        maxWidth={false}
        sx={{ py: 7, paddingLeft: 6, paddingRight: 6 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mx: isMobile ? 0 : 10,
          }}
        >
          <Typography sx={{ fontSize: "h6.fontSize", color: "#D27B2C" }}>
            About us
          </Typography>

          <Typography sx={{ fontSize: "h4.fontSize", fontWeight: "bold" }}>
            What is Cantonica?
          </Typography>

          <Typography sx={{ fontSize: "h6.fontSize", color: "#475467" }}>
            Simply put, Cantonica leverages an app store interface to index
            search results as store listings. Bringing reviews and 5-star app
            ratings to the open web is useful in and of itself, yet having true
            freedom and discoverability on mobile devices — anywhere and
            everywhere in the world — is truly a liberating idea.
          </Typography>
          <Typography sx={{ fontSize: "h6.fontSize", color: "#475467" }}>
            From a practical standpoint, we hope to usher in a new era of fast
            iteration and deployment of new ideas and concepts on the mobile
            web. By avoiding the complexities of traditional app store
            approvals, developers can experiment with numerous different
            features, designs, functionalities, localizations, translations, and
            more — all without the anxiety that one small font change might get
            rejected and gum up months of planning and preparation.
          </Typography>
          <Typography sx={{ fontSize: "h6.fontSize", color: "#475467" }}>
            Moreover, by eliminating the false choice between either developing
            for Android or developing for iOS, Cantonica hastens the arrival of
            a truly borderless mobile web. As it currently stands, apps
            developed in the iPhone-centric Western world often neglect Android
            and thus neglect wide swaths of our planet — while apps developed
            for the Global South oftentimes pay iOS no heed, leading to
            significant disconnect during a time in which we should be working
            together all the more.
          </Typography>
          <Typography sx={{ fontSize: "h6.fontSize", color: "#475467" }}>
            Today is not a time or disconnect or discord — or a time for walled
            gardens keeping users out and profits in. Though we may be named
            after a casino planet in the Star Wars universe, the desire for
            connection, utility, and interconnectedness are not something to be
            gambled with by Silicon Valley plutocrats. It is an uphill journey
            toward the future that we envision — but rebellions are built on
            hope.
          </Typography>
        </Box>
      </Container>

      <Container maxWidth={false} sx={{ bgcolor: "#F9FAFB" }}>
        <Grid
          container
          sx={{ my: !isMobile ? 10 : 0, ml: { md: 0 } }}
          spacing={3}
        >
          <Grid item xs={12} sm={6} sx={{ pl: !isMobile ? 2 : 0 }}>
            <Card>
              <CardMedia
                sx={{
                  height: "70vh",
                  width: "100%",
                }}
                component="img"
                image={MediaImg}
              />
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{ my: { sm: 0, md: 2 }, mx: { xs: 3, sm: 0 } }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                alignContent: "center",
                justifyContent: "start",
                height: "70vh",
                p: 6,
              }}
            >
              <Typography
                sx={{ color: "#A1550FCC", fontSize: { sm: "h6.fontSize" } }}
              >
                Build better, launch faster
              </Typography>

              <Typography
                sx={{
                  color: "black",
                  fontSize: "h4.fontSize",
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                We’re only just getting started on our journey
              </Typography>

              <Grid
                container
                spacing={2}
                sx={{
                  ml: 0,
                  mt: 4,
                  width: "100%",
                }}
              >
                <Grid xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "h4.fontSize",
                        color: "#D27B2C",
                        fontWeight: "bold",
                      }}
                    >
                      400+
                    </Typography>

                    <Typography sx={{ fontSize: "h6.fontSize" }}>
                      Apps
                    </Typography>
                  </Box>
                </Grid>

                <Grid xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "h4.fontSize",
                        color: "#D27B2C",
                        fontWeight: "bold",
                      }}
                    >
                      600%
                    </Typography>

                    <Typography sx={{ fontSize: "h6.fontSize" }}>
                      Installs
                    </Typography>
                  </Box>
                </Grid>

                <Grid xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      mt: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "h4.fontSize",
                        color: "#D27B2C",
                        fontWeight: "bold",
                      }}
                    >
                      10k
                    </Typography>

                    <Typography sx={{ fontSize: "h6.fontSize" }}>
                      Users
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* We are hiring section */}
      <Container maxWidth={false}>
        <Box
          sx={{
            width: { xs: "80%", sm: "80%", md: "50%" },
            mx: "auto",
            my: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography sx={{ fontSize: "h6.fontSize", color: "#A1550FCC" }}>
            We're hiring!
          </Typography>

          <Typography sx={{ fontSize: "h5.fontSize", fontWeight: "bold" }}>
            Join our team!
          </Typography>

          <Typography sx={{ fontSize: "h6.fontSize", color: "#475467" }}>
            Our philosophy is simple — hire a team of diverse, passionate people
            and foster a culture that empowers you to do your best work.
          </Typography>

          <Button
            href="#"
            sx={{
              fontWeight: "bold",
              color: "white",
              bgcolor: "#D27B2C",

              minWidth: "10rem",

              "&:hover": {
                color: "black",
              },
            }}
          >
            Open positions
          </Button>
        </Box>
      </Container>

      <Container maxWidth={false} sx={{ backgroundColor: "#F9FAFB", p: 4 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,
          }}
        >
          <Typography sx={{ fontSize: "h6.fontSize", color: "#A1550F" }}>
            Suggested blogs!
          </Typography>

          <Typography
            sx={{
              fontSize: "h5.fontSize",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            Learn about PWAs and more!
          </Typography>
        </Box>

        <BlogPostRecommendations />
      </Container>

      <Footer />
    </>
  );
};
