import React, { useState, useRef } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import {
  InputPassword,
  InputSimple,
  ButtonSimple,
  ButtonIcon,
} from "../globalComponents/inputs/inputs";
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Container,
  AppBar,
  Toolbar,
} from "@mui/material";
import Fondo from "../globalComponents/imagens/login_screen_image.png";
import CantonicaLogo from "../globalComponents/imagens/logo.svg";
import GoogleLogo from "../globalComponents/imagens/googleIcon.png";
import { LoginSocialGoogle } from "reactjs-social-login";
import { SnackbarBasic } from "../globalComponents/feedBacks/FeedBacks";
import { Navigate } from "react-router-dom";

// Define styles object to be used with MUI components

const styles = {
  hiddenButton: {
    display: "none",
  },
  box: {
    height: "100vh",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
  },
  paper: {
    display: "flex",
    zIndex: 5,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: { xs: 1, lg: 2 },
    boxShadow: {
      xs: "none",
      md: "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    },
    width: { xs: "100%", md: "50%" },
    padding: { xs: 2, md: 4 },
    position: "relative",
  },
  h2: {
    fontSize: "28px",
    alignSelf: "center",
    margin: "5%",
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: "16px",
    alignSelf: "center",
    marginBottom: "15px",
  },
  login_box: {
    width: "100%",
  },
  input: {
    width: "100%",
    marginBottom: "15px",
  },
  button: {
    width: "100%",
    height: "50px",
    borderRadius: "15px",
    background: "#D27B2C",
    ":hover": {
      background: "#D27B2C",
    },
    marginBottom: "15px",
  },
  buttonFace: {
    width: "100%",
    height: "50px",
    borderRadius: "15px",
    color: "white",
    background: "#425993",
    ":hover": {
      background: "#425993",
    },
  },
  buttonGoo: {
    width: "100%",
    height: "50px",
    borderRadius: "4px",
    color: "black",
    background: "#FFFFFF",
    ":hover": {
      background: "#5 384EC",
    },
  },
  text: {
    color: "var(--black-100)",
    width: "100%",
    fontWeight: "600",
    fontSize: "13px",
    textAlign: "center",
    marginBottom: "15px",
  },
  typography: {
    color: "var(--black-100)",
    marginY: "15px",
    width: "100%",
    cursor: "pointer",
    fontWeight: "600",
    fontSize: "13px",
    textAlign: "center",
  },
  span: {
    marginInline: "10px",
    color: "#F60000",
    fontWeight: "600",
    fontSize: "13px",
  },
  error: {
    fontSize: "12px",
    color: "red",
    fontWeight: "bold",
    width: "90%",
    overflow: "hidden",
    alignSelf: "center",
  },
  imgContainer: {
    flex: 1,
    width: "50%",
    display: { xs: "none", md: "flex" },
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  img: {
    width: "100%",
    height: "100vh",
    objectFit: "cover",
  },
  socialButtons: {
    display: "block",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "20px",
    color: "black",
    background: "#FFFFFF",
    ":hover": {
      background: "#5 384EC",
    },
  },
  formActions: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    marginBottom: "15px",
  },
  signupText: {
    bottom: "10px",
    width: "100%",
    textAlign: "center",
    color: "#3E3E3E",
    cursor: "pointer",
    fontWeight: "600",
    fontSize: "13px",
    marginTop: "15px",
  },
  signup_button: {
    font: "#121212",
  },
  navbar: {
    backgroundColor: "white",
    color: "black",
    boxShadow: "none",
    padding: "10px 0",
  },
  navToolbar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    height: "40px",
    marginRight: "10px",
  },
  navTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  dividerContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  line: {
    flexGrow: 1,
    height: "1px",
    backgroundColor: "#E0E0E0", // Light gray color
  },
  text: {
    marginLeft: "10px",
    marginRight: "10px",
    color: "#757575", // Gray color
  },
};

// Define the FormLogin component

export const FormLogin = () => {
  // Refs for handling Google Login
  const googleRef = useRef();

  // State hooks for managing UI states
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    email: "",
    error: "",
  });

  // Hook for navigation
  const navigate = useNavigate();

  // Hook form setup
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Extract errors if any
  const err = errors.user === undefined ? {} : errors.user;

  // Get redirect URI from environment variables
  const REDIRECT_URI = process.env.REDIRECT_URI;

  // Function to handle failure in Google Login
  const handleFailure = (result) => {
    console.log(result);
    console.log("Error occurred");
  };

  // Function to handle Google login
  const handleLoginGoogle = async ({ data }) => {
    const user = {
      name: data.name,
      google_token: data.access_token,
      email: data.email,
      isGoogle: true,
      avatar: data.picture,
      uidGoogle: data.sub,
    };

    try {
      // POST request to authenticate Google user
      const response = await axios.post(`/users/googleauthentication`, user);
      const token = response.data.token;
      const userData = response.data.user;
      localStorage.setItem("token", token);
      localStorage.setItem("xuser", JSON.stringify(userData));

      // Navigate to the previous page or home
      navigate(-1) === undefined ? navigate("/") : navigate(-1);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setMessage({ error: error.response.data.error });
        setTimeout(() => {
          setMessage({ error: "" });
        }, 7000);
      } else {
        setOpenSnackbar(true);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 5000);
      }
    }
  };

  // Function to handle form submission/traditional login
  const onSubmit = async (data) => {
    setLoading(true);

    try {
      // POST request to authenticate user
      const response = await axios.post(`/users/userauthentication`, data.user);

      const { token, user } = response.data;
      // Store token and user data in local storage
      localStorage.setItem("token", token);
      localStorage.setItem("xuser", JSON.stringify(user));

      setLoading(false);
      // Navigate to the previous page or home
      navigate(-1) === undefined ? navigate("/") : navigate(-1);
    } catch (error) {
      setLoading(false);

      if (error.response && error.response.status === 400) {
        setMessage({ error: error.response.data.error });
        setTimeout(() => {
          setMessage({ error: "" });
        }, 7000);
      } else {
        setOpenSnackbar(true);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 5000);
      }
    }
  };

  // Check if the user is already logged in
  const token = localStorage.token;

  return token ? (
    <Navigate to="/" />
  ) : (
    <Box sx={styles.box}>
      <Paper sx={styles.paper} elevation={0}>
        <AppBar position="static" sx={styles.navbar}>
          <Toolbar
            sx={styles.navToolbar}
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={CantonicaLogo} alt="Cantonica Logo" style={styles.logo} />
            <Typography variant="h6" sx={styles.navTitle}>
              Cantonica
            </Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth="xs">
          <Typography variant="h2" sx={styles.h2}>
            Welcome Back
          </Typography>
          <Typography variant="subtitle1" sx={styles.subtitle}>
            Enter your email and password to access your account
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputSimple
              label={"Email"}
              error={err.email ? true : false}
              type={"text"}
              inputProps={{ maxLength: 40, borderRadius: "15px" }}
              styles={styles.input}
              hookForm={{
                ...register("user.email", {
                  required: "This field can’t be empty",
                  pattern: {
                    value:
                      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                    message: "Please, enter a valid email",
                  },
                }),
              }}
            />
            <ErrorMessage
              errors={errors}
              name="user.email"
              render={({ message }) => (
                <span style={styles.error}>{message}</span>
              )}
            />
            <span style={styles.error}>{message.email}</span>
            <InputPassword
              label={"Password"}
              error={err.password ? true : false}
              showPassword={showPassword}
              inputProps={{ maxLength: 16 }}
              setShowPassword={setShowPassword}
              styles={styles.input}
              hookForm={{
                ...register("user.password", {
                  required: "This field can’t be empty",
                  pattern: {
                    value: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
                    message:
                      "The password must be between 8 and 16 characters, with at least one digit, one lower case, and one upper case.",
                  },
                }),
              }}
            />
            <ErrorMessage
              errors={errors}
              name="user.password"
              render={({ message }) => (
                <span style={styles.error}>{message}</span>
              )}
            />
            <span style={styles.error}>{message.error}</span>
            <Box sx={styles.formActions}>
              <FormControlLabel control={<Checkbox />} label="Remember me" />
              <Typography
                variant="subtitle1"
                sx={{
                  color: "#Grey/800",
                  cursor: "pointer",
                  textAlign: "right",
                }}
                onClick={() => navigate("/RecoveryPassword")}
              >
                Forgot Password
              </Typography>
            </Box>
            <ButtonSimple
              styles={styles.button}
              text={loading ? <CircularProgress color="inherit" /> : "Sign In"}
              type={"submit"}
            />
          </form>
          <Typography variant="subtitle1" gutterBottom sx={styles.typography}>
            <Box sx={styles.dividerContainer}>
              <Box sx={styles.line} />
              <Typography sx={styles.text}>Or continue with</Typography>
              <Box sx={styles.line} />
            </Box>
          </Typography>
          <Box sx={styles.socialButtons}>
            <LoginSocialGoogle
              ref={googleRef}
              client_id={
                "763415949181-980aboip7npob76djldc7slp7hniljs1.apps.googleusercontent.com"
              }
              onLogoutFailure={handleFailure}
              redirect_uri={REDIRECT_URI}
              scope="openid profile email"
              discoveryDocs="claims_supported"
              typeResponse="accessToken"
              onResolve={handleLoginGoogle}
              onReject={(err) => {
                setOpenSnackbar(true);
                setTimeout(() => {
                  setOpenSnackbar(false);
                }, 5000);
              }}
            >
              <ButtonIcon
                styles={styles.buttonGoo}
                variant={"contained"}
                text={"Google"}
                startIcon={
                  <img
                    src={GoogleLogo}
                    alt="Google Icon"
                    style={{ width: "24px", height: "24px" }}
                  />
                }
              />
            </LoginSocialGoogle>
          </Box>
        </Container>
        <SnackbarBasic
          open={openSnackbar}
          styles={{ width: "300px" }}
          autoHideDuration={5000}
          severity={"warning"}
          message={"Sorry, there was a problem. Please try again later"}
          vertical={"bottom"}
          horizontal={"center"}
        />
        <Typography
          variant="subtitle1"
          sx={styles.signupText}
          onClick={() => navigate("/Signup")}
        >
          Don't have an account?{" "}
          <span style={styles.signup_button}>Sign Up</span>
        </Typography>
      </Paper>
      <Box sx={styles.imgContainer}>
        <img src={Fondo} alt="Background" style={styles.img} />
      </Box>
    </Box>
  );
};
