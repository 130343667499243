import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Collapse,
  Toolbar,
  Typography,
  useMediaQuery,
  Avatar,
  MenuItem,
  Menu,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import MenuIcon from "@mui/icons-material/Menu";
import { Close } from "@mui/icons-material";
import logo from "../components/globalComponents/imagens/logo.svg";
import { useNavigate, useLocation } from "react-router-dom";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  const nameUser = name.nameUser.name;

  const validation = nameUser.split(" ").length > 1;
  const avatarUrl = name.nameUser.avatar;

  const containsAvatar =
    avatarUrl && typeof avatarUrl === "string" && avatarUrl.trim().length > 0; //Check if we do have an avatar or not

  return {
    sx: {
      bgcolor: stringToColor(nameUser),
      cursor: "pointer",
    },
    src: avatarUrl,
    children:
      !containsAvatar && validation
        ? `${nameUser.toUpperCase().split(" ")[0][0]}${
            nameUser.toUpperCase().split(" ")[1][0]
          }`
        : `${nameUser.split(" ")[0][0]}`,
  };
}

const HeaderDesktop = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const token = localStorage.token; //The name of the token would be 'random_shit_token'

  const nameUser =
    localStorage.xuser !== undefined ? JSON.parse(localStorage.xuser) : "";

  const [anchorEl1, setAnchorEl1] = useState(null); //This is used to show multiple options of user if he is logged in
  const open1 = Boolean(anchorEl1);

  return (
    <AppBar position="static" sx={{ bgcolor: "#FFF0E3" }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          boxShadow: 3,
        }}
      >
        <Box
          onClick={() => {
            navigate("/");
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            gap: {
              sm: 1,
              md: 2,
              lg: 3,
            },
          }}
        >
          <Box component={"img"} src={logo} />

          <Typography
            sx={{
              fontSize: {
                sm: "h6.fontSize",
                xl: "h4.fontSize",
              },
              color: "black",
              fontWeight: "bold",
            }}
          >
            CANTONICA
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            color: "#A1550F",
            gap: {
              sm: 1,
              md: 2,
            },
            fontSize: {
              sm: "14px",
              md: "20px",
              xl: "32px",
            },
          }}
        >
          <Button
            onClick={() => navigate("/")}
            sx={{
              color: "inherit",
              textTransform: "none",
              fontWeight: pathname === "/" ? "bold" : "none",
              fontSize: "inherit",
            }}
          >
            Home
          </Button>
          <Button
            onClick={() => navigate("#")}
            sx={{
              color: "inherit",
              textTransform: "none",
              fontWeight: pathname === "/listYourApp" ? "bold" : "none",
              fontSize: "inherit",
            }}
          >
            List your app
          </Button>
          <Button
            onClick={() => navigate("/aboutus")}
            sx={{
              color: "inherit",
              textTransform: "none",
              fontWeight: pathname === "/aboutus" ? "bold" : "none",
              fontSize: "inherit",
            }}
          >
            About
          </Button>
          <Button
            onClick={() => navigate("/ContactUs")}
            sx={{
              color: "inherit",
              textTransform: "none",
              fontWeight: pathname === "/ContactUs" ? "bold" : "none",
              fontSize: "inherit",
            }}
          >
            Contact
          </Button>
        </Box>

        {token ? (
          <Box
            sx={{
              cursor: "pointer",
              alignSelf: "end",
              marginBottom: "10px",
            }}
          >
            <Avatar
              {...stringAvatar({ nameUser })}
              id="basic-button"
              aria-controls={open1 ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open1 ? "true" : undefined}
              onClick={(event) => {
                setAnchorEl1(event.currentTarget);
              }}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl1}
              open={open1}
              onClose={() => {
                setAnchorEl1(null);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl1(null);
                  navigate("/profile");
                }}
              >
                Profile
              </MenuItem>
              {token && nameUser.isAdmin ? (
                <MenuItem
                  onClick={() => {
                    setAnchorEl1(null);
                    navigate("/Dashboard_admin");
                  }}
                >
                  Dashboard
                </MenuItem>
              ) : (
                ""
              )}
              <MenuItem
                onClick={() => {
                  setAnchorEl1(null);
                  localStorage.clear();
                  navigate("/");
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              color: "#A1550F",
              gap: {
                sm: 1,
                md: 2,
              },
              fontSize: {
                sm: "14px",
                md: "20px",
                xl: "32px",
              },
            }}
          >
            <Button
              onClick={() => navigate("/login")}
              sx={{
                color: "inherit",
                fontWeight: "bold",
                textTransform: "none",
                border: 1,
                borderColor: "#A1550F",
                fontSize: "inherit",
              }}
            >
              Sign in
            </Button>
            <Button
              onClick={() => navigate("/signup")}
              sx={{
                color: "white",
                fontWeight: "bold",
                bgcolor: "#D27B2C",
                borderRadius: 2,
                textTransform: "none",
                "&:hover": {
                  color: "black",
                },
                fontSize: "inherit",
              }}
            >
              Signup
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

const HeaderMobile = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const theme = useTheme();
  const token = localStorage.token; //The name of the token would be 'random_shit_token'

  const nameUser =
    localStorage.xuser !== undefined ? JSON.parse(localStorage.xuser) : "";

  const [anchorEl1, setAnchorEl1] = useState(null); //This is used to show multiple options of user if he is logged in
  const open1 = Boolean(anchorEl1);

  const isBetween500and600 = useMediaQuery(
    theme.breakpoints.between(450, "sm")
  ); // Check if the screen size is between 500 and 600 px

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    console.log("Button is pressed");
    setIsOpen(!isOpen);
  };

  return (
    <AppBar position="static">
      <Toolbar
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: 3,
          backgroundColor: "#FFF0E3",
        }}
      >
        {!isOpen ? (
          <MenuIcon
            onClick={handleClick}
            sx={{
              color: "black",
              fontSize: isBetween500and600 ? 50 : 30,
              "&:hover": { cursor: "pointer" },
            }}
          />
        ) : (
          <Close
            onClick={handleClick}
            sx={{
              color: "black",
              fontSize: isBetween500and600 ? 50 : 30,
              "&:hover": { cursor: "pointer" },
            }}
          />
        )}

        <Box component={"img"} src={logo} />

        {token && (
          <Box
            sx={{
              cursor: "pointer",
            }}
          >
            <Avatar
              {...stringAvatar({ nameUser })}
              id="basic-button"
              aria-controls={open1 ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open1 ? "true" : undefined}
              onClick={(event) => {
                setAnchorEl1(event.currentTarget);
              }}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl1}
              open={open1}
              onClose={() => {
                setAnchorEl1(null);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl1(null);
                  navigate("/profile");
                }}
              >
                Profile
              </MenuItem>
              {token && nameUser.isAdmin ? (
                <MenuItem
                  onClick={() => {
                    setAnchorEl1(null);
                    navigate("/Dashboard_admin");
                  }}
                >
                  Dashboard
                </MenuItem>
              ) : (
                ""
              )}
              <MenuItem
                onClick={() => {
                  setAnchorEl1(null);
                  localStorage.clear();
                  navigate("/");
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>

      <Collapse in={isOpen}>
        <Box
          sx={{
            pt: 2,
            display: "flex",
            flexDirection: "column",
            color: "black",
            alignItems: "center",
            backgroundColor: "white",
            gap: 1,
            fontSize: isBetween500and600 ? 25 : 20,
          }}
        >
          <Button
            onClick={() => navigate("/")}
            sx={{
              color: "inherit",
              fontWeight: pathname === "/" ? "bold" : "none",
              textTransform: "none",
              fontSize: "inherit",
            }}
          >
            Home
          </Button>
          <Button
            onClick={() => navigate("#")}
            sx={{
              color: "inherit",
              fontWeight: pathname === "/listYourApp" ? "bold" : "none",
              textTransform: "none",
              fontSize: "inherit",
            }}
          >
            List your app
          </Button>
          <Button
            onClick={() => navigate("/aboutus")}
            sx={{
              color: "inherit",
              fontWeight: pathname === "/aboutus" ? "bold" : "none",
              textTransform: "none",
              fontSize: "inherit",
            }}
          >
            About
          </Button>
          <Button
            onClick={() => navigate("/ContactUs")}
            sx={{
              color: "inherit",
              fontWeight: pathname === "/ContactUs" ? "bold" : "none",
              textTransform: "none",
              fontSize: "inherit",
            }}
          >
            Contact
          </Button>

          {!token && (
            <Button
              onClick={() => navigate("/login")}
              sx={{
                textTransform: "none",
                border: 1,
                borderColor: "#D27B2C",
                color: "#D27B2C",
                width: "90%",
                fontSize: "inherit",
              }}
            >
              Sign In
            </Button>
          )}
          {!token && (
            <Button
              onClick={() => navigate("/signup")}
              href="/signup"
              sx={{
                color: "white",
                backgroundColor: "#D27B2C",
                borderRadius: 2,
                textTransform: "none",
                width: "90%",
                fontSize: "inherit",
              }}
            >
              Signup
            </Button>
          )}
        </Box>
      </Collapse>
    </AppBar>
  );
};

export const Header = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  return isSm ? <HeaderMobile /> : <HeaderDesktop />;
};
