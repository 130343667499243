import React from "react";

import { Box, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";

export const SearchSiteResultList = ({ searchData, totalPages }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        maxHeight: "250px",
        overflow: "scroll",
        gap: 2,
        py: 2,
      }}
    >
      {searchData.map((data, index) => {
        return (
          <Box
            sx={{ display: "flex", gap: 2, width: "100%", cursor: "pointer" }}
            key={index}
            onClick={() => {
              navigate(`/sites/${data._id}`);
            }}
          >
            <Box
              component={"img"}
              width={"30px"}
              height={"30px"}
              src={data.logo}
            />

            <Typography
              sx={{
                color: "#A1550F",
                width: "100%",
              }}
            >
              {" "}
              {data.name}
            </Typography>
          </Box>
        );
      })}

      {totalPages && totalPages > 1 && (
        <Typography sx={{ cursor: "pointer", color: "#A1550F" }}>
          Load More
        </Typography>
      )}
    </Box>
  );
};
