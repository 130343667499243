import React from "react";
import { Box, Typography } from "@mui/material";
import Fondo from "./globalComponents/imagens/backgroundInitial.png"
import Cantonica from "./globalComponents/imagens/cantonica.png"
import notFoundImage from "./globalComponents/imagens/notFoundImage.png"
import { useNavigate } from "react-router-dom";
import { Header } from "./Header";
import { Footer } from "./Footer";

export const NotFound = () => {
    const navigate = useNavigate();

    return(
        <>
        <Header sx={{ margin: 0, padding: 0 }} />
        <Box
          sx={{
            height: { xs: "60vh", lg: "65vh" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 0, 
            padding: 0 
          }}
        >
          <Box
            component="img"
            src={notFoundImage}
            alt="404 Not Found Image"
            sx={{
              width: "85%",
              alignSelf: "center",
              zIndex: 5,
              marginTop: "3%",
            }}
          />
          <Box
            sx={{
              display: "flex",
              zIndex: 5,
              flexDirection: "column",
              alignItems: "center", 
              marginTop: 3,
              gap: { xs: 1, lg: 0 },
              width: { xs: "100%", md: "50%" },
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "13px", sm: "18px" },
                textAlign: "center", // Centering text
                marginBottom: { xs: "3%", lg: "3%" },
                fontWeight: "medium",
                width: { md: "100%" },
                color: "#404345",
              }}
            >
              Oops, this page is located beyond known galaxy...
            </Typography>
          </Box>
        </Box>
        <Footer />
        
        </>
    )
}