import React, { useState, useEffect, createRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import logo from "../components/globalComponents/imagens/logo.svg";

import {
  Box,
  Typography,
  Button,
  keyframes,
  IconButton,
  Modal,
} from "@mui/material";

import {
  NavigateBefore,
  FileDownload,
  FavoriteBorder,
  Favorite,
  Twitter,
  Facebook,
  Instagram,
  LinkedIn,
  YouTube,
  ExpandMore,
  ExpandLess,
  Star,
  StarHalf,
  ArrowBackIos,
  ArrowForwardIos,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { marked } from "marked";
import { Footer } from "./Footer";

const sampleApp = {
  Image:
    "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
  Name: "Spotify",
  Description: `# Experience the Ultimate Music Streaming Platform with Spotify
    Discover millions of tracks from your favorite artists, albums, and genres, all in one place. With personalized recommendations based on your listening habits, finding new music has never been easier. Create your own playlists or explore curated playlists tailored to your mood and activities. Enjoy uninterrupted listening with ad-free premium subscriptions, and download your favorite songs for offline playback. Dive into podcasts, audiobooks, and exclusive content from top creators to expand your entertainment horizon. Connect with friends, share your favorite tracks, and discover what they're listening to. Whether you're working out, relaxing at home, or on the go, Spotify has the perfect soundtrack for every moment. Elevate your music experience today with Spotify.
  ## Features
  
  - **Discover Millions of Tracks**: Explore a vast library of songs, albums, and genres from your favorite artists.
  - **Personalized Recommendations**: Get tailored music suggestions based on your listening habits and preferences.
  - **Create Custom Playlists**: Craft your own playlists or delve into expertly curated ones for any mood or occasion.
  - **Offline Playback**: Download your favorite songs and podcasts to listen offline, perfect for when you're on the move.
  - **Diverse Content**: Explore podcasts, audiobooks, and exclusive content from top creators to expand your entertainment options.
  `,
  Category: "Education",
  Rating: 5.0,
  Developer: "Spotify, LLC",
  Launched: "March 1, 2012",
  Size: 2.4,
  averageRating: 3.2,
  Socials: {
    Twitter: "https://x.com/Spotify",
    Facebook: "https://www.facebook.com/spotifyusa",
    LinkedIn: "https://www.linkedin.com/company/spotify",
    Youtube: "https://www.youtube.com/@Spotify",
    Instagram: "https://www.instagram.com/spotify/",
  },
  Reviews: [
    {
      name: "Ashwin Dhanaswamy",
      rating: 5.0,
      created_at: "2022-01-20T00:00:00.000+00:00",
      Country: "United States",
      comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
         sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
         quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    },
    {
      name: "Ashwin Dhanaswamy",
      rating: 3.0,
      created_at: "2023-01-20T00:00:00.000+00:00",
      Country: "United States",
      comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
           sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
           quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    },
    {
      name: "Ashwin Dhanaswamy",
      rating: 4.0,
      created_at: "2024-05-19T00:00:00.000+00:00",
      Country: "United States",
      comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
           sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
           quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    },
    {
      name: "Ashwin Dhanaswamy",
      rating: 1.0,
      created_at: "2024-05-20T00:00:00.000+00:00",
      Country: "United States",
      comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
           sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
           quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    },
    {
      name: "Ashwin Dhanaswamy",
      rating: 3.0,
      created_at: "2024-05-20T00:00:00.000+00:00",
      Country: "United States",
      comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
             sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
             quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    },
  ],
};

const SiteImageCarousel = ({ siteImages, startCarouselIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(startCarouselIndex);
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? siteImages.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === siteImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <IconButton
        onClick={handlePrev}
        sx={{
          position: "absolute",
          left: 0,
          color: "grey",
        }}
      >
        <ArrowBackIos />
      </IconButton>
      <img
        src={siteImages[currentIndex]}
        alt={`Slide ${currentIndex}`}
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "800px",
          maxHeight: "800px",
        }}
      />
      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          right: 0,
          color: "grey",
        }}
      >
        <ArrowForwardIos />
      </IconButton>
    </Box>
  );
};

const GetTotalStars = ({ rating, starColor }) => {
  let floorRating = Math.floor(rating);
  let ratingArr = Array.from(Array(floorRating).keys());

  if (floorRating !== rating) ratingArr.push(0.5);

  return (
    <Box>
      {ratingArr.map((key, index) => {
        if (key !== 0.5) return <Star sx={{ color: starColor }} />;
        else return <StarHalf sx={{ color: starColor }} />;
      })}
    </Box>
  );
};

const ProgressBar = ({ reviews }) => {
  let totalReviews = reviews.totalReviews;

  /*Deleting Total Reviews because we are iterating over every review and showing the progress bar.
  If not deleted it will show the total Reviews entry which we do not want in UI  */
  delete reviews.totalReviews;

  return (
    <Box
      sx={{
        width: "70%",
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      {Object.keys(reviews).map((keyValue, keyIndex) => {
        return (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography>{keyValue}</Typography>
            <Star />
            <Box
              sx={{
                width: "100%",
                height: "10%",
                border: 2,
                borderColor: "#C8C5BF",
                borderRadius: 2,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: `${Math.round(
                    (reviews[keyValue] / totalReviews) * 100
                  )}%`,
                  height: "100%",
                  border: 2,
                  borderColor: "black",
                  borderRadius: 2,
                  position: "absolute",
                  top: "-2px",
                  left: "-1px",
                  backgroundColor: "black",
                }}
              />
            </Box>

            <Typography sx={{ color: "#1A1A1A", textDecoration: "underline" }}>
              {" "}
              {reviews[keyValue]}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export const Product = () => {
  const { siteId } = useParams();
  const [productData, setProductData] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const [commentPageNumber, setCommentPageNumber] = useState(0);
  const commentsPerPage = 4; // Number of comments to show per page
  const [comments, setComments] = useState([]); //All the comments requested till now
  const [showMoreComment, setShowMoreComment] = useState([]); //All the comments show more state.
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true); // If there are no more commments it would be set to false
  const [openSiteImageCarousel, setOpenSiteImageCarousel] = useState(false); // Show the list of site Images as a carousel
  const [siteCarouselCurrentImagePositon, setSiteCarouselCurrentImagePosition] =
    useState(0); // Lets say user click Image number 3 so the carosel should start from image 3

  const handleOpenSiteImageCarousel = () => setOpenSiteImageCarousel(true);
  const handleCloseSiteImageCarousel = () => setOpenSiteImageCarousel(false);

  const setFavorite = () => {
    console.log("Button is pressed");
    setIsFavorite(!isFavorite);
  };

  useEffect(() => {
    async function fetchProduct() {
      try {
        console.log(siteId);
        const response = await axios.get(`/sites/${siteId}`);

        if (response.status !== 200) {
          throw new Error("Failed to fetch the product data");
        }

        const { data } = response;

        setProductData(data);
      } catch (error) {
        console.error("Error fetching product data", error);
      }
    }

    fetchProduct();
  }, []);

  // useEffect(()=>{
  //   fetchComments();
  // },[]);

  /*
  const fetchComments = async () => {
    try {
      const response = await axios.get(
        `https://your-api/comments?page=${page}&limit=${commentsPerPage}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setComments((comments) => [...comments, ...data]); // Append new comments to existing comments
      setCommentPageNumber((commentPageNumber) => commentPageNumber + 1); // Increment page to fetch next set of comments
    } catch (error) {
      console.log(error.message);
    }
  };
  */

  const ShowComment = () => {
    return (
      <Box sx={{ display: "flex", gap: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          {/* <Typography sx={{ fontWeight: "bold" }}>{name}</Typography>
          <Typography>{getTimeDiff(created_at)}</Typography> */}
        </Box>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box></Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const getTimeDiff = (date) => {
    const temp = new Date(date);
    const currDate = new Date();

    let timeDiff = currDate - temp;

    const getDays = timeDiff / (1000 * 3600 * 24);

    //If the time diff is greater than or equal to 2 years
    if (getDays >= 730) return `About ${Math.floor(getDays / 365)} years ago`;
    //If the time diff is between 1 and 2 years
    else if (getDays >= 365 && getDays < 730) return "About 1 year ago";
    //If the time diff is greater than or equal to 2 months
    else if (getDays >= 60)
      return `About ${Math.floor(getDays / 30)} months ago`;
    //If the time diff is between 1 and  2 months
    else if (getDays > 30) return `About 1 month ago`;
    //If the time diff between 1 and 30 days
    else if (getDays > 1) return `About ${getDays} days ago`;
    else if (getDays === 1) return `About ${getDays} day  ago`;
    //If the date of the comment is less than 1 day
    else if (timeDiff >= 7200)
      return `About ${Math.floor(timeDiff / 3600)} hours ago`;
    else if (timeDiff >= 3600) return `About 1 hour ago`;
    //If the commment submitted is less than 1 hour ago
    else if (timeDiff >= 120)
      return `About ${Math.floor(timeDiff / 60)} minutes ago`;
    //If the commment submitted is more than 1 minute  but less than 2 minutes ago
    else if (timeDiff > 60) return `About 1 minute ago`;

    //Display the seconds
    return `About ${timeDiff} seconds ago`;
  };

  const getDate = (date) => {
    const temp = new Date(date);
    const year = temp.getUTCFullYear();
    const month = temp.getUTCMonth(); // getUTCMonth() returns zero-indexed month (0 for January, 1 for February, etc.)
    const newDate = temp.getUTCDate();

    return `${monthNames[month]} ${newDate}, ${year}`;
  };

  const getAppSize = (size) => {
    if (size > 1024) return `${(size / 1024).toFixed(2)} GB`;

    return `${size.toFixed(2)} MB`;
  };

  return (
    <>
      {productData && (
        <Box>
          {/* Header of the product page */}
          <Box
            sx={{
              width: "100%",
              height: "10vh",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              boxShadow: 3,
            }}
          >
            <Link
              to={`/`}
              style={{
                textDecoration: "none",
              }}
            >
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <NavigateBefore
                  sx={{
                    color: "#919EAB",
                  }}
                />
                <img src={logo} alt="Cantonica Logo" />
              </Box>
            </Link>

            <Box
              component={"img"}
              src={productData.logo}
              alt="product logo"
              sx={{ width: "3vw", height: "6vh" }}
            ></Box>

            <Box
              sx={{
                backgroundColor: "#FFF0E3",
                borderColor: "#FFF0E3",
                display: "flex",
                width: "7vw",
                gap: 2,
                borderRadius: "16px",
                height: "5vh",
                alignItems: "center",
                cursor: "pointer",
                mr: 2,
              }}
            >
              <FileDownload sx={{ color: "#B47439" }} />
              <Typography sx={{ color: "#B47439" }}>Install</Typography>
            </Box>
          </Box>
          {/*End of the header code */}

          {/* Start of the App Name with image section  */}
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#F9FAFB",
              height: "fit-content",
              display: "flex",
              py: 5,
              gap: 4,
            }}
          >
            <Box
              sx={{
                width: "30%",
                pl: 5,
                ml: 3,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                  }}
                >
                  <img src={productData.logo} width={"50vw"}></img>
                  <Typography
                    sx={{ fontSize: "h6.fontSize", fontWeight: "bold" }}
                  >
                    {" "}
                    {productData.name}
                  </Typography>
                </Box>
                {!isFavorite && (
                  <FavoriteBorder
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={setFavorite}
                  ></FavoriteBorder>
                )}
                {isFavorite && (
                  <Favorite
                    sx={{
                      cursor: "pointer",
                      color: "yellow",
                      borderColor: "black",
                      stroke: "black",
                      borderRadius: "50%",
                      backgroundColor: "white",
                    }}
                    onClick={setFavorite}
                  ></Favorite>
                )}
              </Box>
              <Typography
                sx={{
                  fontSize: "p.fontSize",
                  fontWeight: "bold",

                  textAlign: "left",
                }}
              >
                Free to Install. Additional Charges May Apply
              </Typography>

              <Button
                variant="contained"
                size="large"
                sx={{
                  color: "black",
                  backgroundColor: "#FFA743",
                  borderRadius: "16px",
                  fontWeight: "bold",
                  textTransform: "none",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "#FFA743",
                  },
                }}
              >
                Install on Device
              </Button>

              <Typography
                sx={{
                  textDecoration: "underline",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                Preview App
              </Typography>
            </Box>

            {/*Images of the application */}
            <Box
              sx={{
                width: "75%",
                display: "flex",
                gap: 1,
                height: "fit-content",
              }}
            >
              <Box
                component="img"
                src={productData.media[0]}
                sx={{
                  width:
                    productData.media && productData.media.length === 1
                      ? "100%"
                      : "70%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSiteCarouselCurrentImagePosition(0);
                  handleOpenSiteImageCarousel();
                }}
              ></Box>
              {productData.media && productData.media.length - 1 > 0 && (
                <Box
                  sx={{
                    width: "30%",
                    borderColor: "yellow",
                    flex: "column",
                    alignContent: "space-around",
                    gap: 3,
                  }}
                >
                  <Box
                    component="img"
                    src={productData.media[1]}
                    sx={{
                      width: "100%",
                      height:
                        productData.media.length === 2
                          ? "100%"
                          : productData.media.length === 3
                          ? "50%"
                          : "33%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSiteCarouselCurrentImagePosition(1);
                      handleOpenSiteImageCarousel();
                    }}
                  />
                  <Box
                    component="img"
                    src={productData.media[2]}
                    sx={{
                      width: "100%",
                      height:
                        productData.media.length === 2
                          ? "100%"
                          : productData.media.length === 3
                          ? "50%"
                          : "33%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSiteCarouselCurrentImagePosition(2);
                      handleOpenSiteImageCarousel();
                    }}
                  />

                  <Box
                    component="img"
                    src={productData.media[3]}
                    sx={{
                      width: "100%",
                      height:
                        productData.media.length === 2
                          ? "100%"
                          : productData.media.length === 3
                          ? "50%"
                          : "33%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSiteCarouselCurrentImagePosition(3);
                      handleOpenSiteImageCarousel();
                    }}
                  />
                </Box>
              )}
            </Box>

            {productData && productData.media.length > 0 && (
              <Modal
                open={openSiteImageCarousel}
                onClose={handleCloseSiteImageCarousel}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <SiteImageCarousel
                    siteImages={productData.media}
                    startCarouselIndex={siteCarouselCurrentImagePositon}
                  />
                </Box>
              </Modal>
            )}
          </Box>
          {/* End of the App Name with image section  */}

          {/* Start of the App description  */}
          <Box
            sx={{
              width: "100%",
              height: "fit-content",
              display: "flex",
              py: 5,
              gap: 4,
            }}
          >
            <Box
              sx={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                gap: 2,
                pl: 5,
                ml: 3,
              }}
            >
              <Typography>About this App</Typography>
              {productData.created_at && (
                <Box>
                  <Typography sx={{ color: "#707070" }}>Launched</Typography>
                  <Typography sx={{ color: "#707070" }}>
                    {getDate(productData.created_at)}
                  </Typography>
                </Box>
              )}
              {productData.size && (
                <Box>
                  <Typography sx={{ color: "#707070" }}>Size</Typography>
                  <Typography sx={{ color: "#707070" }}>
                    {getAppSize(productData.size)}
                  </Typography>
                </Box>
              )}

              {productData.developer_name && (
                <Box>
                  <Typography sx={{ color: "#707070" }}>Developer</Typography>
                  <Typography
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "#707070",
                    }}
                  >
                    {productData.developer_name}
                  </Typography>
                </Box>
              )}

              {productData.socials &&
                Object.keys(productData.socials).length > 0 && (
                  <Typography>On the Web</Typography>
                )}

              {productData.socials &&
                Object.keys(productData.socials).length > 0 && (
                  <Box sx={{ display: "flex" }}>
                    {productData.socials.twitter && (
                      <Link
                        to={productData.socials.twitter}
                        style={{ textDecoration: "none", color: "black" }}
                        target="_blank"
                      >
                        <Twitter sx={{ width: "4vw", height: "4vh" }} />
                      </Link>
                    )}

                    {productData.socials.instagram && (
                      <Link
                        to={productData.socials.instagram}
                        style={{ textDecoration: "none", color: "black" }}
                        target="_blank"
                      >
                        <Instagram sx={{ width: "4vw", height: "4vh" }} />
                      </Link>
                    )}

                    {productData.socials.meta && (
                      <Link
                        to={productData.socials.meta}
                        style={{ textDecoration: "none", color: "black" }}
                        target="_blank"
                      >
                        <Facebook sx={{ width: "4vw", height: "4vh" }} />
                      </Link>
                    )}

                    {productData.socials.youtube && (
                      <Link
                        to={productData.socials.youtube}
                        style={{ textDecoration: "none", color: "black" }}
                        target="_blank"
                      >
                        <YouTube sx={{ width: "4vw", height: "4vh" }} />
                      </Link>
                    )}

                    {productData.socials.linkedin && (
                      <Link
                        to={productData.socials.linkedin}
                        style={{ textDecoration: "none", color: "black" }}
                        target="_blank"
                      >
                        <LinkedIn sx={{ width: "4vw", height: "4vh" }} />
                      </Link>
                    )}
                  </Box>
                )}
            </Box>

            <Box
              sx={{
                width: "70%",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                pr: 6,
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "h6.fontSize" }}>
                Description
              </Typography>

              <Box
                component="div"
                dangerouslySetInnerHTML={{
                  __html: showMoreDescription
                    ? `${marked(productData.description)}`
                    : `${marked(productData.description.substring(0, 500))}`,
                }}
              ></Box>

              {!showMoreDescription ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#A1550F" }}>Show More</Typography>
                  <ExpandMore
                    sx={{
                      cursor: "pointer",
                      width: "4vw",
                      height: "4vh",
                      color: "#A1550F",
                    }}
                    onClick={() => {
                      setShowMoreDescription(!showMoreDescription);
                    }}
                  ></ExpandMore>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#A1550F" }}>Show Less</Typography>
                  <ExpandLess
                    onClick={() => {
                      setShowMoreDescription(!showMoreDescription);
                    }}
                    sx={{
                      cursor: "pointer",
                      width: "4vw",
                      height: "4vh",
                      color: "#A1550F",
                    }}
                  ></ExpandLess>
                </Box>
              )}
            </Box>
          </Box>

          {/* End of the App description  */}

          {/*Comment Section Start */}
          {sampleApp.Reviews && sampleApp.Reviews.length > 0 && (
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#F9FAFB",
                height: "fit-content",
                display: "flex",
                py: 5,
                gap: 4,
              }}
            >
              {/* Currently  we are using static data. Please change once the apis are made */}
              <Box
                sx={{
                  width: "30%",
                  pl: 5,
                  ml: 3,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  textAlign: "left",
                }}
              >
                <Typography
                  sx={{ fontWeight: "bold", fontSize: "h5.fontSize" }}
                >
                  Reviews{" "}
                  <Typography
                    sx={{
                      display: "inline",
                      fontWeight: "bold",
                      fontSize: "h5.fontSize",
                    }}
                  >
                    {`(${sampleApp.Reviews.length})`}
                  </Typography>
                </Typography>
                <Typography sx={{ color: "#707070", fontWeight: "bold" }}>
                  Overall rating
                </Typography>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "h6.fontSize" }}
                  >
                    {sampleApp.averageRating}
                  </Typography>
                  <GetTotalStars
                    starColor="#FFAE1B"
                    rating={sampleApp.averageRating}
                  />
                </Box>
                <ProgressBar
                  reviews={{
                    1: 30,
                    2: 20,
                    3: 50,
                    4: 40,
                    5: 50,
                    totalReviews: 190,
                  }}
                />
              </Box>
              {comments.length > 0 && (
                <Box
                  sx={{
                    width: "75%",
                    display: "flex",
                    gap: 2,
                    height: "fit-content",
                  }}
                >
                  <ShowComment />
                </Box>
              )}
            </Box>
          )}

          {/*Comment Section End*/}
        </Box>
      )}

      <Footer />
    </>
  );
};
