import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import axios from 'axios';

// Set default configurations for Axios requests.
// Default base URL is set from environment variable, or falls back to local development server URL.
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

// Enable credentials such as cookies, authorization headers or TLS client certificates
// to be sent along with requests made by Axios to different domains.
axios.defaults.withCredentials = true;

// Register a request interceptor on Axios to manipulate configurations
// before the request is sent to the server.
axios.interceptors.request.use(
  config => {
    // Retrieve the token from localStorage where it's stored after authentication.
    const token = localStorage.getItem('token');
    // If a token exists, append it to the request headers as a Bearer token.
    // This is a common practice for handling authentication in API requests.
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    // Return the modified configuration to proceed with the request.
    return config;
  },
  error => {
    // If an error occurs while setting up the request, reject the promise with the error.
    // This allows catching request initialization errors in the calling code.
    console.error(error);
    return Promise.reject(error);
  }
);

 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <App />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
