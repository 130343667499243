import { Typography } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Box,
  TextField,
  useMediaQuery,
  Divider,
  Avatar,
  Button,
  Modal,
  Fade,
  Grid,
} from "@mui/material";
//import { Button } from "@mui/material-next";
import { useTheme } from "@mui/material/styles";
import { Copyright } from "@mui/icons-material";
import logo from "./../components/globalComponents/imagens/logo.svg";

export const Footer = () => {
  //Function used when user subscibes to the newsletter successfully
  const handleModalClose = () => {
    setShowMessage(false);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setErrors([]);
  };

  const handleButtonSubmit = async () => {
    const regex = /^[a-zA-Z0-9._-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/;

    const validationErrors = [];

    if (email.trim() === "")
      validationErrors.push("Please enter some email Address");

    if (!regex.test(email))
      validationErrors.push("Please enter valid email Address");

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
    } else {
      let data = {
        email: email,
      };
      try {
        const response = await axios.post(`/newsletter/subscribe`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status !== 200) {
          validationErrors.push(response.message);
          setErrors(validationErrors);
          throw new Error(response.message);
        }

        setIsSubscribed(true);
      } catch (error) {
        validationErrors.push(error.message);
        setErrors(validationErrors);
        console.error("Error subscibing to the news lettter", error);
      }
    }
  };

  const [email, setEmail] = useState(""); //Store the email id when the user types
  const [errors, setErrors] = useState([]);
  const [showMessage, setShowMessage] = useState(false); //Show the message when user subsribes to the news letter successfully
  const [isSubscribed, setIsSubscribed] = useState(false);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        minWidth: "320px", // note: for smallest screen size, around 320px in width
        height: "309px",
        borderTop: "1px solid #E8E8E8",
        padding: "64px 6vw 48px 6vw",
        // marginLeft: { xs: "5%", md: 0, sm: 0 },
        marginBottom: { xs: "5rem", md: 0, sm: 0, lg: 0 },
      }}
    >
      <Grid container>
        <Grid item sm={6} md={6}>
          {" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "#101828",
                fontSize: "20px",
                textAlign: "left",
                fontWeight: "600",
              }}
            >
              Join our newsletter
            </Typography>

            <Typography
              sx={{
                color: "#475467",
                fontSize: "16px",
                textAlign: "left",
                fontWeight: "400",
              }}
            >
              We’ll send you a nice letter once per week. No spam.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          sm={6}
          md={6}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: { xs: "2rem", md: 0, sm: 0, lg: 0 },
            }}
          >
            {isSubscribed ? (
              <Box sx={{ height: "44px" }}>
                <Typography color="#475466">Thanks for subscribing!</Typography>
              </Box>
            ) : (
              <>
                <Box>
                  <TextField
                    required
                    id="outlined"
                    placeholder="Enter your email"
                    value={email}
                    disabled={isSubscribed ? true : false}
                    onChange={handleEmailChange}
                    error={errors.length > 0}
                    helperText={errors.length > 0 ? errors[0] : ""}
                    inputProps={{
                      style: {
                        width: "100%",
                        height: "44px",
                        boxSizing: "border-box",
                      },
                    }}
                  />
                </Box>
                <Button
                  sx={{
                    bgcolor: "#D27B2C",
                    color: "white",
                    minWidth: "115px",
                    "&:hover": { color: "black" },
                    height: "3rem",
                    borderRadius: "8px",
                    boxShadow: "0px 1px 2px 0px #1018280D",
                    fontWeight: "600",
                    fontSize: "16px",
                    width: "115px",
                    height: "44px",
                    marginLeft: "1.2rem",
                  }}
                  onClick={handleButtonSubmit}
                >
                  Subscribe
                </Button>
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 5, borderBottom: "2px solid #E8E8E8" }} />

      <Grid
        container
        sx={{
          width: { sm: "100%", md: "100%" },
          display: "flex",
          justifyContent: "space-between",
          mx: "auto",
          gap: 1,
          mt: 4,
        }}
      >
        <Grid item sm={4} md={4}>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <img src={logo} alt="Cantonica Logo" />
            <Typography
              sx={{
                fontSize: "20px",
                color: "black",
                fontWeight: "700",
              }}
            >
              CANTONICA
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              marginTop: { xs: "1rem", md: "0", sm: "0" },
            }}
          >
            <Typography sx={{ color: "#667085", fontSize: "15px" }}>
              © 2024 Cantonica.com. All rights reserved.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
