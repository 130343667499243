import React, { useState } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Link } from "react-router-dom";
import MuiPhoneNumber from "mui-phone-number";

import {
  Grid,
  Box,
  Typography,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import axios from "axios";

export const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    phone: "",
    formChecked: "",
  });

  const [serverError, setServerErrror] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [formChecked, setFormChecked] = useState(false);

  const handlePhoneChange = (e) => {
    setPhoneNumber(e);
  };

  const handleCheckBox = (e) => {
    setFormChecked(e.target.checked);
  };

  const validate = () => {
    let errors = {};

    const regex = /^[a-zA-Z0-9._-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/; //Regex to check if email is valid

    if (!formData.firstName || formData.firstName.trim().length === 0)
      errors.firstName = "First Name is required";

    if (!formData.lastName || formData.lastName.trim().length === 0)
      errors.lastName = "Last Name is required";

    if (!formData.email || formData.email.trim().length === 0)
      errors.email = "Email is required";
    else if (!regex.test(formData.email.trim()))
      errors.email = "Please enter valid email address";

    if (!phoneNumber || phoneNumber.trim().length === 0)
      errors.phone = "Phone is required";
    else if (phoneNumber.trim().length < 10)
      errors.phone = "Phone Number should be atleast 10 digits long";

    if (!formData.message || !formData.message.trim().length === 0)
      errors.phone = "Message is required";
    else if (
      formData.message.trim().length < 10 ||
      formData.message.trim().length > 2000
    )
      errors.message = "Message should be between 10 and 2000 characters";

    if (!formChecked) errors.formChecked = "Please tick the checkbox";

    return errors;
  };

  const handleFormSubmit = async (e) => {
    try {
      e.preventDefault();
      const errors = validate();

      formData.phone = phoneNumber.replace(/[\(\)\-\s]/g, ""); //Regex expression used to remove formatting of phone number

      if (Object.keys(errors).length === 0) {
        const response = await axios.post("/contactus", formData);

        if (response.status !== 200) {
          setServerErrror(response.data.error);
        } else {
          alert("form submitted successfully");
        }
      } else {
        // Set errors
        setFormErrors(errors);
      }
    } catch (error) {
      console.log(error.response.data.error);
      setServerErrror(error.response.data.error);
    }
  };
  return (
    <>
      <Header />
      <Grid container sx={{ mt: 5, p: 2 }}>
        <Grid item sm={6} sx={{ textAlign: "left" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              width: "90%",
              margin: "auto",
              p: 3,
            }}
          >
            <Typography sx={{ fontSize: "h4.fontSize", fontWeight: "bold" }}>
              Get in touch
            </Typography>
            <Typography sx={{ color: "#475467" }}>
              Our friendly team would love to hear from you.
            </Typography>
            {/*Contact Us form begin */}
            <Box component="form" onSubmit={handleFormSubmit}>
              <Box
                sx={{
                  display: { sm: "flex" },
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box>
                  <Typography sx={{ color: "#344054", pb: 1 }}>
                    First name
                    <Typography sx={{ color: "red", display: "inline" }}>
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    variant="outlined"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleFormChange}
                    fullWidth
                    error={!!formErrors.firstName}
                    helperText={formErrors.firstName}
                  />
                </Box>

                <Box sx={{ sm: { width: "40%" } }}>
                  <Typography sx={{ color: "#344054", pb: 1 }}>
                    Last name{" "}
                    <Typography sx={{ color: "red", display: "inline" }}>
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    variant="outlined"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleFormChange}
                    required
                    fullWidth
                    error={!!formErrors.lastName}
                    helperText={formErrors.lastName}
                  />
                </Box>
              </Box>
              <Box>
                <Typography sx={{ color: "#344054", py: 1 }}>
                  Email
                  <Typography sx={{ color: "red", display: "inline" }}>
                    *
                  </Typography>
                </Typography>
                <TextField
                  variant="outlined"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleFormChange}
                  required
                  fullWidth
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                />
              </Box>

              <Box>
                <Typography sx={{ color: "#344054", py: 1 }}>
                  Phone Number
                  <Typography sx={{ color: "red", display: "inline" }}>
                    *
                  </Typography>
                </Typography>
                <MuiPhoneNumber
                  defaultCountry={"us"}
                  autoFormat={true}
                  variant="outlined"
                  name="phone"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  required
                  fullWidth
                  error={!!formErrors.phone}
                  helperText={formErrors.phone}
                />
              </Box>

              <Box>
                <Typography sx={{ color: "#344054", py: 1 }}>
                  Message
                  <Typography sx={{ color: "red", display: "inline" }}>
                    *
                  </Typography>
                </Typography>
                <TextField
                  variant="outlined"
                  name="message"
                  placeholder="Leave us a message..."
                  value={formData.message}
                  onChange={handleFormChange}
                  fullWidth
                  multiline
                  rows={5}
                  maxRows={6}
                  required
                  error={!!formErrors.message}
                  helperText={formErrors.message}
                />
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", py: 1 }}>
                <Checkbox required onChange={handleCheckBox} />
                <Typography sx={{ color: "#475467" }}>
                  You agree to our friendly{" "}
                  <Typography
                    sx={{ textDecoration: "underline", display: "inline" }}
                  >
                    <Link
                      to={`/`}
                      style={{
                        textDecoration: "none",
                        color: "#475467",
                      }}
                    >
                      privacy policy.
                    </Link>
                  </Typography>
                  .
                </Typography>
              </Box>

              {formErrors.formChecked && formErrors.formChecked.length > 0 && (
                <Typography sx={{ color: "red" }}>
                  {formErrors.formChecked}
                </Typography>
              )}

              <Button
                type="submit"
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#D27B2C",
                  color: "white",
                  width: "80%",
                  height: "5vh",
                  "&:hover": {
                    backgroundColor: "#D27B2C",
                  },
                }}
              >
                {" "}
                Send Message
              </Button>
            </Box>
            {/*Contact Us form end */}

            {/*If the person tries to escape the front end checks*/}
            {serverError && serverError.trim().length > 0 && (
              <Typography sx={{ color: "red" }}>{serverError}</Typography>
            )}
          </Box>
        </Grid>

        <Grid item sm={6}>
          <Box
            component="img"
            sx={{ height: "80%", width: "100%" }}
            src="https://st.depositphotos.com/2291517/2866/i/450/depositphotos_28667683-stock-photo-get-in-touch.jpg"
          />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};
